import React,{ useEffect, useState } from 'react'
import { Tooltip,Area,AreaChart,linearGradient,LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts'
import { NavLink } from "react-router-dom";
import './Capteur2.css';

function Capteur2() {

    const [records, setRecords] = useState([]);
    const [recordsdix, setRecordsdix] = useState([]);
    const [recordsmois, setRecordsmois] = useState([]);
    const [recordstroismois, setRecordstroismois] = useState([]);

    useEffect(()=>{
        fetch('https://megaupload.newcloud.space/capteur2/jour')
         .then(response => response.json())
         .then(data => setRecords(data))
         .catch(err=>console.log(err))
       },[])
     
       useEffect(()=>{
         fetch('https://megaupload.newcloud.space/capteur2/dixjour')
          .then(response => response.json())
          .then(data => setRecordsdix(data))
          .catch(err=>console.log(err))
        },[])
     
        useEffect(()=>{
         fetch('https://megaupload.newcloud.space/mois')
          .then(response => response.json())
          .then(data => setRecordsmois(data))
          .catch(err=>console.log(err))
        },[])
     
        useEffect(()=>{
         fetch('https://megaupload.newcloud.space/troismois')
          .then(response => response.json())
          .then(data => setRecordstroismois(data))
          .catch(err=>console.log(err))
        },[])
        var b = records.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))
        var c = recordsdix.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))


  return (
    <div>Capteur2
          <h1>Hauteur eau turbine </h1>
          <h3>spectre:qz4j2ewy6t2zzaedurq2my7tff8fzxvsye3qvffl66u9ltgdzl502qpgmqy5w</h3>
          <NavLink
        to="/capteur1"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      > 
        Capteur1
      </NavLink> || 
      <NavLink
        to="/capteur2"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        Capteur2
      </NavLink>

      <h2>Niveau 24 H</h2>

  <AreaChart width={1300} height={300} data={b}>
  
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
 
</AreaChart>
<h2>DIX JOURS</h2>


<AreaChart width={1300} height={300} data={c}>
 <defs>
   <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
     <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
     <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
   </linearGradient>
   <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
     <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
     <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
   </linearGradient>
 </defs>
 <XAxis dataKey="name" />
 <YAxis />
 <CartesianGrid strokeDasharray="3 3" />
 <Tooltip />
 <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
 <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>

    </div>
  )
}

export default Capteur2
