import React,{ useEffect, useState } from 'react';
import { Tooltip,Area,AreaChart,linearGradient,LineChart, Line, CartesianGrid, XAxis, YAxis,ReferenceLine } from 'recharts';
import { NavLink } from "react-router-dom";
import './Capteur1.css';





function Home() {
  var datatotest=[{"id":3319,"capteur":"capteur1","valeur":45.09,"createdAt":"2024-02-26T17:40:02.000Z","updatedAt":"2024-02-26T17:40:02.000Z"},
                {"id":3320,"capteur":"capteur1","valeur":42.05,"createdAt":"2024-02-26T17:50:03.000Z","updatedAt":"2024-02-26T17:50:03.000Z"},
                {"id":3321,"capteur":"capteur1","valeur":43.6,"createdAt":"2024-02-26T18:00:02.000Z","updatedAt":"2024-02-26T18:00:02.000Z"}]

  
  
  const [records, setRecords] = useState([]);
  const [recordsdix, setRecordsdix] = useState([]);
  const [recordsmois, setRecordsmois] = useState([]);
  const [recordstroismois, setRecordstroismois] = useState([]);

  useEffect(()=>{
   fetch('https://megaupload.newcloud.space/jour')
    .then(response => response.json())
    .then(data => setRecords(data))
    .catch(err=>console.log(err))
  },[])

  useEffect(()=>{
    fetch('https://megaupload.newcloud.space/dixjour')
     .then(response => response.json())
     .then(data => setRecordsdix(data))
     .catch(err=>console.log(err))
   },[])

   useEffect(()=>{
    fetch('https://megaupload.newcloud.space/mois')
     .then(response => response.json())
     .then(data => setRecordsmois(data))
     .catch(err=>console.log(err))
   },[])

   useEffect(()=>{
    fetch('https://megaupload.newcloud.space/troismois')
     .then(response => response.json())
     .then(data => setRecordstroismois(data))
     .catch(err=>console.log(err))
   },[])
   
  
  const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, 
  {name: 'Page B', uv: 470, pv:0, amt: 2800},
  {name: 'Page C', uv: 170, pv: 1100, amt: 1800},
     ];

     var key=0
//var a = datatotest.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))

 var b = records.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))
 var c = recordsdix.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))
 var d = recordsmois.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))
 var e = recordstroismois.map((list,index)=>({name:list.createdAt,uv: parseFloat(list.valeur)}))

  return (
    
    <div>
      <h3>spectre:qqlgr2vlxz8nhj965xn8g5ex22k4pg560nxmze44xs58akt8hrlw2jd3064dr</h3>
      <h1>Graphique niveau eau </h1>
      <h3>spectre:qqlgr2vlxz8nhj965xn8g5ex22k4pg560nxmze44xs58akt8hrlw2jd3064dr</h3>
      <h2>Niveau 24 H</h2>
      <NavLink
        to="/capteur1"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      > 
        Capteur1
      </NavLink> || 
      <NavLink
        to="/capteur2"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        Capteur2
      </NavLink>
      

  <AreaChart width={1300} height={300} data={b}>
  
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis type="number" domain={[0, 100]}/>
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <ReferenceLine y={90} label="90 - 1m eau" stroke="red" />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
 
</AreaChart>
 <h2>DIX JOURS</h2>


 <AreaChart width={1300} height={300} data={c}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <ReferenceLine y={90} label="90 - 1m eau" stroke="red" />
  <ReferenceLine y={107} label="75 - 75 Cm eau" stroke="yellow" />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>

<h2>NIVEAU MOIS</h2>
<AreaChart width={1300} height={300} data={d}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <ReferenceLine y={90} label="90 - 1m eau" stroke="red" />
  <ReferenceLine y={107} label="75 - 75 Cm eau" stroke="yellow" />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>

<h2>NIVEAU 3 MOIS</h2>
<AreaChart width={1300} height={300} data={e}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <ReferenceLine y={90} label="90 - 1m eau" stroke="red" />
  <ReferenceLine y={107} label="75 - 75 Cm eau" stroke="yellow" />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>

    {/* <div>
      <h2>JOKE</h2>
       <ul>
        {records.map((list,index)=>(
          <li key={index}>{list.id} | {list.capteur} |{ list.valeur }| {list.createdAt} </li>
        ))}
       </ul>
    </div>  */}
    
    </div>
  )
}

export default Home;
